import {
	ApolloClient,
	ApolloProvider,
	// eslint-disable-next-line import/named
	NormalizedCacheObject,
	createHttpLink,
	from,
	fromPromise,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';
import { cache } from './cache';
import { PLATFORM_NAME } from 'constants/common/PlatformName';
import HttpStatus from 'constants/http';
import { REFRESH_USER_ACCESS_TOKEN } from 'entites/user/refresh-user-access-token.graphql';
import { getFromLocalStorage, setInLocalStorage } from 'utils/helpers/local-storage/localStorage';
import { handleLogOut } from 'utils/helpers/log-in-log-out/handleLogOut';
import { FC } from 'react';
import { RefreshTokenDto } from 'utils/types/user';

// eslint-disable-next-line prefer-const
export let client: ApolloClient<NormalizedCacheObject>;;

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_API_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
	const access_token = getFromLocalStorage('access_token');

	return {
		headers: {
			...headers,
			Authorization: access_token ? `${access_token}` : '',
		},
	};
});

export const getNewToken = ({ refresh_token, user_id }: RefreshTokenDto) => {
	return client
		.mutate({
			mutation: REFRESH_USER_ACCESS_TOKEN,
			variables: {
				credentials: { refresh_token, user_id },
			},
		})
		.then((response) => {
			return response.data?.refreshUserAccessToken;
		});
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
	if (graphQLErrors) {
		console.log(graphQLErrors);

		const notAuthorized = graphQLErrors.some(
			({ extensions }) => {
				if (extensions.originalError) {
					return (extensions.originalError as any).statusCode === HttpStatus.NOT_AUTHORIZED;
				} else {
					return false;
				}
			}
		);

		if (notAuthorized) {
			const user_id = getFromLocalStorage('user_id');
			const refresh_token = getFromLocalStorage('refresh_token');

			if (!user_id || !refresh_token) {
				handleLogOut();
			}

			return fromPromise(
				getNewToken({
					refresh_token,
					user_id,
				})
					.then(data => {
						if (!data.access_token) {
							throw new Error('not valid refresh token');
						}

						setInLocalStorage('access_token', 'Bearer ' + data.access_token);

						return data;
					})
					.catch(() => {
						handleLogOut();
					}),
			)
				.filter(value => Boolean(value))
				.flatMap(data => {
					const oldHeaders = operation.getContext().headers;

					operation.setContext({
						headers: {
							...oldHeaders,
							Authorization: data.access_token
								? `Bearer ${data.access_token}`
								: '',
						},
					});

					return forward(operation);
				});
		} else {
			return undefined;
		}
	} else {
		return undefined;
	}
});

client = new ApolloClient({
	link: from([errorLink, authLink, httpLink]),
	cache: cache,
	name: PLATFORM_NAME,
});

type Props = {
	children: React.ReactNode | JSX.Element | JSX.Element[];
};

const WithApollo: FC<Props> = ({ children }) => (
	<ApolloProvider client={client}>
		{children}
	</ApolloProvider>
);

export default WithApollo;
