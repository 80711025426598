import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { UserContext } from 'context/UserContext';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import { useNavigate } from 'react-router-dom';
import { BaseFullProps, NotifToastProps, BaseShortProps } from 'utils/types/notifications/templates';
import { OneColorIcon } from '../../Story/Icon';
import { ToastWrapper } from '../../UI/Wrapper/ToastWrapper';
import { Body } from '../../UI/Toast/Body';

export const NewYear2025Toast: FC<NotifToastProps> = ({
  id,
  handleClose,
}) => {
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/writer/notifications/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Body
        text="As we reflect on this amazing journey, we are filled with gratitude for every one of you. Since we first launched Passion in 2021, you have been the heart and soul of our community, pouring your creativity, passion, and love into every story you’ve shared."
        title="Happy Holidays!"
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const NewYear2025Short: FC<BaseShortProps> = ({
  id,
  isSelected,
  user_readed,
  createdAt,
  toggleSelect,
  handleOpenNotification,
}) => {
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
      bg={bg}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            Happy Holidays!
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          As we reflect on this amazing journey, we are filled with gratitude for every one of you. Since we first launched Passion in 2021, you have been the heart and soul of our community, pouring your creativity, passion, and love into every story you’ve shared.
        </Text>
      </Flex>
    </Flex>
  );
};

export const NewYear2025Full: FC<BaseFullProps> = ({
  createdAt,
}) => {
  const { t } = useTranslation();
  const date = notificationDateFormatter(createdAt);
  const { user } = useContext(UserContext);

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      h="fit-content"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              color={colors['primary-violet-30']}
              img="/images/hero/heart.svg"
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {t('Passion Team')}
            </Text>
            {' <support@passionapp.co>'}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="282px"
        h="168px"
        src={"/images/templates/newYearImage.png"}
        loading="eager"
        alt="icon"
      />

      <Text
        variant="primary-black-text"
        size="poppins20"
        lineHeight="36px"
        letterSpacing={0}
        fontWeight={600}
      >
        {t(`Hi, ${user?.first_name}!`)}
      </Text>

      <Flex direction="column" maxW="600px" mb="44px">
        <Text
          variant="primary-black-text"
          size="poppins20"
          lineHeight="32px"
          letterSpacing="normal"
          fontWeight={400}
          maxW="600px"
          mb="32px"
          textAlign="center"
        >
          As we reflect on this amazing journey, we are filled with gratitude for every one of you. Since we first launched Passion in 2021, you have been the heart and soul of our community, pouring your creativity, passion, and love into every story you’ve shared. You’ve helped shape this platform into a space where readers can escape, connect, and experience unforgettable romances, all thanks to your incredible talent and dedication.
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20"
          lineHeight="32px"
          letterSpacing="normal"
          fontWeight={400}
          maxW="600px"
          textAlign="center"
        >
          This holiday season, we want to take a moment to express our deepest thanks for your unwavering support, your hard work, and your commitment to our shared vision.  Together, we have turned the pages of many chapters and we cannot wait for the future that awaits us.
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20"
          lineHeight="32px"
          letterSpacing="normal"
          maxW="600px"
          fontWeight={400}
          textAlign="center"
        >
          May the upcoming year be brimming with inspiration, success, and even more passionate stories waiting to be shared with the world.
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20"
          lineHeight="32px"
          letterSpacing="normal"
          fontWeight={400}
          textAlign="center"
        >
          Thank you for being a part of the Passion family. We couldn’t have come this far without you.
        </Text>
      </Flex>

      <Text
        variant="primary-black-text"
        size="poppins20"
        lineHeight="32px"
        maxW="600px"
        fontWeight={600}
        mb="20px"
        textAlign="center"
        whiteSpace="pre-wrap"
      >
        Wishing you a warm and wonderful holiday season and a new year filled with love, creativity, and success!
      </Text>

      <Flex align="center" direction="column">
        <Image
          w="40px"
          h="40px"
          src="/images/hero/heart.svg"
          loading="eager"
          alt="heart"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
        >
          {t('Best Regards,')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="28px"
          fontWeight={500}
        >
          {t('Passion Team')}
        </Text>
      </Flex>
    </Flex>
  );
};